<template>
  <v-expansion-panels accordion multiple v-model="panels" :focusable="true" :readonly="$vuetify.breakpoint.mdAndUp">
    <v-expansion-panel class="mb-3" :key="0">
      <v-expansion-panel-header>
        <div class="subtitle-1 font-weight-medium primary--text">1.- Registro de Experiencias Laborales</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-3">
        <InfoExperiencia />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import InfoExperiencia from "@/components/Experiencia";
export default {
  components: {
   InfoExperiencia
  },
  data() {
    return {
      panels: [0]
    };
  }
};
</script>